/** @format */

import React from "react";
import { CSVLink } from "react-csv";

const ExportCSVButton = ({ data }) => {
  const headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Vendor Name", key: "vendorName" }, // Ensure vendor name is included
    { label: "FFMID", key: "FFMID" },
    { label: "Carrier", key: "carrier" },
    { label: "Health Sherpa", key: "healthSherpa" },
    { label: "Agent Name", key: "agentName" },
    { label: "Date", key: "todaysDate" },
    { label: "Status", key: "status" },
  ];

  const csvData = data.map((form) => ({
    firstName: form.firstName,
    lastName: form.lastName,
    phoneNumber: form.phoneNumber,
    vendorName: form.vendorName, // Include vendor name
    FFMId: form.FFMId,
    carrier: form.carrier,
    healthSherpa: form.healthSherpa,
    agentName: form.agentName,
    todaysDate: form.majorMed.billingDate,
    status: form.majorMed.status,
  }));

  return (
    <CSVLink data={csvData} headers={headers} filename={"form-data.csv"}>
      Export CSV
    </CSVLink>
  );
};

export default ExportCSVButton;
